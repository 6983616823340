/* FlexGrid styles are not emitted by default, the feature flag must be turned on */

@use '@carbon/react' with (
  $use-flexbox-grid: true
);
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/colors';
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/theme' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/charts-react/styles' as *;

html {
  background-color: $layer-01;
}

/// Remove overrides once Carbon bugs are fixed upstream.
/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 66rem) {
  .cds--header__menu-toggle__hidden {
    display: flex;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.cds--content {
  margin-top: 3rem;
  padding: 0;
}

.cds--content .cds--css-grid {
  max-width: 100%;
  @include breakpoint(md) {
    padding-left: 0px;
  }
}

.cds--css-grid {
  padding-right: 0px;
  @include breakpoint(md) {
    padding-right: 0px;
  }
}

.cds--multi-select__wrapper.MyMultiSelect {
  width: 30%;
}

.cds--modal-container {
  width: auto;
}
.cds--modal-header {
  margin-block-end: 0.5rem;
  padding-block-start: 1rem;
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
  grid-column: 1 / -1;
  grid-row: 1 / 1;
}

.cds--modal-header__heading {
  box-sizing: border-box;
  font-family: inherit;
  vertical-align: baseline;
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #161616);
  width: fit-content;
  padding: 0px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: 0px;
}

.toasts-container {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 12px;
  position: fixed;
  z-index: 9999;
  right: 0.5em;
}

.cds--toast-notification {
  inline-size: auto;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  padding-left: $spacing-06;
}

.landing-page__heading {
  @include type-style('productive-heading-05');
}

.tabs-group-content {
  padding: $spacing-10 0 $spacing-10 $spacing-06;
}

.landing-page__subheading {
  @include type-style('productive-heading-03');

  font-weight: 600;
}

.landing-page__p {
  @include type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;

  @include breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}

.landing-page__label {
  @include type-style('heading-01');
}

.tabs-group {
  background-color: $layer-01;
  padding: 0 $spacing-03;
}

.landing-page__banner {
  margin-left: -20px;
  margin-right: -20px;

  @include breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.cds--tab--list {
  justify-content: flex-end;
  padding-right: 1rem;
}

.cds--subgrid--wide {
  //fix needed to keep the subgrid from scrolling horizontally
  margin-right: 1rem;
}

.landing-page h2,
p,
h3 {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.landing-page ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}

.MuiCard-root {
  margin-right: 1em;
}

@media (max-width: 320px) {
  .action-icons {
    display: none;
  }
}

.sidemenu-icon {
  margin-top: 5px;
}

.sidemenu-p {
  float: right;
  margin-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.cds--side-nav__item.menu_item_child {
  margin-left: 16px;
}

pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
  margin: 20px;
}

body {
  line-height: 1;
}

.pageStyles {
  width: 100vw;
  height: 100vh;
  background: rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #LoginComponent {
    width: 700px;
  }
}

.modalStyles {
  /* undo size, position, etc. */
  width: 100%;
  height: initial;
  display: block;
  position: static;
  div[role='dialog'] {
    width: 100%;
  }
}

h3.cds--modal-header__heading {
  font-weight: 400;
  font-size: xx-large;
}

/* hide close button X */
.cds--modal-close {
  display: none !important;
}

.cds--modal-footer.cds--btn-set {
  height: 48px;
}
/* position the arrow in the submit button */
button.cds--btn.cds--btn--primary {
  max-inline-size: none;
}

button.cds--btn.cds--btn--primary.login {
  padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) * 0 + 1rem - 0.0625rem);
  width: 100%;
}

/* remove tooltip */
.cds--assistive-text,
.cds--assistive-text::after,
.cds--text-input--password__visibility__toggle::before {
  display: none !important;
}

.no-account {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.need-help {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  margin-top: 1rem;
}

div.cds--form-item.cds--text-input-wrapper.domainlistID {
  margin-bottom: 0;
  width: 100%;
}

div.cds--form-item.cds--text-input-wrapper {
  margin-bottom: 1rem;
  width: 300px;
  max-width: 300px;
}

div.cds--form-item.cds--checkbox-wrapper {
  margin-top: 15px;
}

.myDiv,
.myDiv:hover {
  width: 100%;
}

.RaSimpleFormIterator-line {
  border-bottom: none;
  margin-bottom: 10px;
}

.cds--data-table-header__description {
  max-inline-size: unset;
}

.cds--progress-bar.progress-error {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-top: 11px;
}

.cds--progress-bar.progress-success,
.cds--progress-bar.progress-warning {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-top: 25px;
}

.RaArrayInput-root.domainlistarray > label {
  margin-bottom: 10px;
  margin-top: 5px;
}

.cds--form-item.rule-value.cds--text-input-wrapper {
  margin-left: auto;
}
