.container {
    /* height: 100vh; */
    /* background: #773d8c; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    display: flex;
    flex-direction: row;
}

@import url(https://fonts.googleapis.com/css?family=Audiowide);


@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    40% {
        transform: translateY(-40px);
    }
    80%,
    100% {
        transform: translateY(0px);
    }
}


.loading__letter {
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    font-family: "Audiowide";
    color: #fec468;
}

.loading__letter:nth-child(2) {
    animation-delay: 0.1s;
}
.loading__letter:nth-child(3) {
    animation-delay: 0.2s;
}
.loading__letter:nth-child(4) {
    animation-delay: 0.3s;
}
.loading__letter:nth-child(5) {
    animation-delay: 0.4s;
}
.loading__letter:nth-child(6) {
    animation-delay: 0.5s;
}
.loading__letter:nth-child(7) {
    animation-delay: 0.6s;
}
.loading__letter:nth-child(8) {
    animation-delay: 0.8s;
}
.loading__letter:nth-child(9) {
    animation-delay: 1s;
}
.loading__letter:nth-child(10) {
    animation-delay: 1.2s;
}

